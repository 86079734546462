import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { FaqStore } from './store/index';

export const FaqModule: StorefrontModule = function ({
  app,
  store,
  router,
  moduleConfig,
  appConfig
}) {
  store.registerModule('faq', FaqStore)
};
