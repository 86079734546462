import config from 'config'
import dayjs from 'dayjs'
import fetch from 'isomorphic-fetch'
import { ActionTree } from 'vuex'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import RootState from '@vue-storefront/core/types/RootState'
import { Logger } from '@vue-storefront/core/lib/logger'
import Review from '@vue-storefront/core/modules/review/types/Review'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import i18n from '@vue-storefront/i18n'
import * as types from './mutation-types'
import ReviewState from '../types/ReviewState'

const checkReviewsAreVisited = (reviews, start, end) => {
  return reviews && reviews.slice(start, end).every(review => review)
}

const actions: ActionTree<ReviewState, RootState> = {
  upvote (ctx, payload) {
    return ctx.dispatch('vote', { value: 1, reviewId: payload.reviewId, sku: payload.sku })
  },
  downvote (ctx, payload) {
    return ctx.dispatch('vote', { value: -1, reviewId: payload.reviewId, sku: payload.sku })
  },
  async vote (ctx, { sku, value, reviewId }) {
    const url = config.stampedIo.vote.replace('{{sku}}', sku)

    // TODO extract helper
    const handle = (promise) => promise.then(data => ([data, undefined])).catch(error => Promise.resolve([undefined, error]));

    const [response, error] = await handle(fetch(url, {
      method: 'POST',
      body: JSON.stringify({ vote: value, reviewId })
    }))
    if (error) {
      Logger.error(error)
      return false
    }
    return response.json()
  },
  async edit () {
    // TODO in separate PR
  },
  async remove () {
    // TODO in separate PR
  },
  async getSummary (ctx, payload) {
    const skuList = payload.skus
    const baseApiUrl = config.api.url
    const endpointUrl = baseApiUrl + config.stampedIo.summary
    const alreadyFetchedSkus = Object.keys(ctx.state.summary)
    const skusToFetch = skuList.filter(sku => !alreadyFetchedSkus.includes(sku))
    if (skusToFetch.length === 0) return
    const url = endpointUrl + skusToFetch.map((sku, index) => {
      if (index === 0) return `?skus[]=${sku}`
      return `&skus[]=${sku}`
    }).join('')
    let response
    try {
      response = await fetch(url).then(res => res.json())
      if (!response?.result?.summary) {
        throw new Error('Cannot get reviews summary')
      }
      ctx.commit(types.REVIEW_UPD_SUMMARY, { summary: response.result.summary })
    } catch (err) {
      Logger.error(err, 'Stamped-io', { payload, summaryResponse: response })()
    }
  },
  async list (ctx, payload) {
    ctx.commit(types.REVIEW_IS_ERROR, { message: '' })
    const perPage = config.stampedIo.reviewsPerPage
    const baseApiUrl = config.api.url
    const { forceFetch, ...query } = payload
    let { sku, page = 1, sortBy } = query
    sku = sku === 'all' ? 'all' : +sku;

    const start = (page - 1) * perPage
    const end = (page - 1) * perPage + perPage
    const areReviewsVisited = checkReviewsAreVisited(ctx.state.reviews[sku], start, end)
    if (!forceFetch && areReviewsVisited) return

    await ctx.dispatch('validateReviewsCache')
    if (forceFetch) {
      ctx.commit(types.DUMP_CACHE, { sku })
      await ctx.dispatch('validateReviewsCache', { forceInvalidate: true })
    }
    const [cachedReviews, totalReviews] = await ctx.dispatch('pullReviewsCache')

    if (cachedReviews[sku]) {
      const reviews = cachedReviews[sku]
      ctx.commit(types.REVIEW_SET_REVIEWS, { sku, reviews })
      await ctx.dispatch('storePageReviews', { sku, reviews: reviews.slice(start, end), page, perPage, totalReviews })
    }

    const reviewsUrlWithParameters: string = config.stampedIo.list_reviews_endpoint
      .replace('{{productSku}}', sku)
      .replace('{{page}}', page)
      .replace('{{sortingMethod}}', sortBy || '')
      .replace('{{take}}', perPage)

    let reviewsResponse
    try {
      const areReviewsCached = !!cachedReviews[sku] && checkReviewsAreVisited(cachedReviews[sku], start, end)
      !areReviewsCached && ctx.commit(types.REVIEW_IS_LOADING, { status: true })
      ctx.commit(types.REVIEW_IS_LOADING, { status: true })

      const reviewsRequest = await fetch(baseApiUrl + reviewsUrlWithParameters)
      reviewsResponse = await reviewsRequest.json()
      if (!reviewsResponse.result?.reviews) {
        const message = `Reviews response doesn't contains any result`
        ctx.commit(types.REVIEW_IS_LOADING, { status: false })
        ctx.commit(types.REVIEW_IS_ERROR, { message })
        throw new Error(message)
      }
      const { reviews, totalReviews } = reviewsResponse.result
      await ctx.dispatch('storePageReviews', { sku, reviews, page, perPage, totalReviews })
      await ctx.dispatch('cacheReviews', { reviews: ctx.state.reviews, totalReviews })
    } catch (err) {
      Logger.error(err, 'Stamped-io', { payload, reviewsResponse })()
    }
  },
  async storePageReviews (ctx, { sku, reviews, page, perPage, totalReviews }) {
    ctx.commit(types.REVIEW_UPD_TOTAL_REVIEWS, { sku, totalReviews })
    ctx.commit(types.REVIEW_UPD_REVIEWS, { sku, reviews, page, perPage, total: totalReviews })
    ctx.commit(types.REVIEW_UPD_PAGES_DISCOVERED, { sku, page })
    ctx.commit(types.REVIEW_IS_LOADING, { status: false })

    if (!perPage || !totalReviews) return
    const totalPages = Math.round(totalReviews / perPage)
    ctx.commit(types.REVIEW_UPD_TOTAL_PAGES, { sku, totalPages })
  },
  async add (ctx, review: Review) {
    const baseApiUrl = config.api.url
    const createReviewUrl = baseApiUrl + config.stampedIo.endpoint
    EventBus.$emit('notification-progress-start', i18n.t('Adding a review ...'))
    const {
      productId,
      author,
      email,
      location,
      reviewRating,
      reviewTitle,
      reviewMessage,
      reviewRecommendProduct,
      productName,
      productSKU,
      productImageUrl,
      reviewSource
    } = review

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const request = new Request(createReviewUrl, {
      method: 'POST',
      body: JSON.stringify({
        productId,
        author,
        email,
        location,
        reviewRating,
        reviewTitle,
        reviewMessage,
        reviewRecommendProduct,
        productName,
        productSKU,
        productImageUrl,
        reviewSource
      }),
      headers
    })
    await fetch(request)

    EventBus.$emit('notification-progress-stop')

    EventBus.$emit('clear-add-review-form')
  },
  async cacheReviews (ctx, { reviews, totalReviews }) {
    const StampedStorage = StorageManager.get('stamped-io')
    await StampedStorage.setItem(`reviews`, [reviews, totalReviews, dayjs().format('YYYY-MM-DD')])
  },
  async validateReviewsCache (ctx, { forceInvalidate = false } = {}) {
    const StampedStorage = StorageManager.get('stamped-io')
    const [_, __, cachedReviewsDate] = await ctx.dispatch('pullReviewsCache')
    const isNotVisitedToday = dayjs().diff(cachedReviewsDate, 'day') > config.stampedIo.cacheReviewsDays - 1

    if (isNotVisitedToday || forceInvalidate) {
      await StampedStorage.setItem('reviews', [{}, 0, 0])
    }
  },
  async pullReviewsCache () {
    const StampedStorage = StorageManager.get('stamped-io')

    return (await StampedStorage.getItem('reviews')) || [{}, 0, 0]
  }
}

export default actions
