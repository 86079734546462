import { Module } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import RootState from '@vue-storefront/core/types/RootState'
import RecommendationsState from '../types/RecommendationsState'

export const reviewStore: Module<RecommendationsState, RootState> = {
  namespaced: true,
  state: {
    userIdentifierEmail: null
  },
  actions,
  mutations
}
