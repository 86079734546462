import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import FeedbackState from '../types/FeedbackState'

const mutations: MutationTree<FeedbackState> = {
  [types.SET_STEP] (state, step) {
    state.currentStep = step
  },
  [types.SET_QUESTIONS] (state, questions) {
    state.questions = questions
  },
  [types.UPDATE_FORM_DATA] (state, formData) {
    state.formData = { ...state.formData, ...formData };
  },
  [types.SET_PROGRESSBAR_PERCENTAGE] (state, len) {
    state.stepPercentage = len
  }
}

export default mutations
