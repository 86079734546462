import * as types from './mutation-types';
import {
  createDiffLog,
  notifications,
  validateProduct,
  createOrderData,
  createShippingInfoData,
  productsEquals
} from '@vue-storefront/core/modules/cart/helpers';
import config from 'config'
import { Logger } from '@vue-storefront/core/lib/logger';
import Product from '@vue-storefront/core/modules/catalog/types/Product'

import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { cartHooksExecutors } from '@vue-storefront/core/modules/cart/hooks';
import { CartService } from '@vue-storefront/core/data-resolver';
import CartItem from '@vue-storefront/core/modules/cart/types/CartItem';
import { createActionProcessingToken } from '../helpers/actionsProcessing'
import { router } from '@vue-storefront/core/app';
import CartItemOption from 'core/modules/cart/types/CartItemOption';
import CartItemTotals from 'core/modules/cart/types/CartItemTotals';
import createCartItemForUpdate from '@vue-storefront/core/modules/cart/helpers/createCartItemForUpdate';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { formatShippingAddress } from '@vue-storefront/core/helpers/adress';
export default {
  // EXTENDING to add a hook that sets cart's state to processing
  async addItems ({ commit, dispatch, getters }, { productsToAdd, forceServerSilence = false, gifts = [] }) {
    const ACTION_TOKEN = createActionProcessingToken('updateQuantity')
    dispatch('setActionProcessing', { isInProgress: true, actionToken: ACTION_TOKEN })
    let productIndex = 0
    const diffLog = createDiffLog()
    for (let product of productsToAdd) {
      const errors = validateProduct(product)
      diffLog.pushNotifications(notifications.createNotifications({ type: 'error', messages: errors }))

      if (errors.length === 0) {
        const { status, onlineCheckTaskId } = await dispatch('checkProductStatus', { product })

        if (status === 'volatile' && !config.stock.allowOutOfStockInCart) {
          diffLog.pushNotification(notifications.unsafeQuantity())
        }
        if (status === 'out_of_stock') {
          diffLog.pushNotification(notifications.outOfStock())
        }

        if (status === 'ok' || status === 'volatile') {
          EventBus.$emit('add-to-cart', {
            product,
            coupon: getters['getCoupon']
          })
          commit(types.CART_ADD_ITEM, {
            product: { ...product, onlineStockCheckid: onlineCheckTaskId }
          })
        }
        if (productIndex === (productsToAdd.length - 1) && (!getters.isCartSyncEnabled || forceServerSilence)) {
          diffLog.pushNotification(notifications.productAddedToCart())
        }
        productIndex++
      }
    }

    let newDiffLog = await dispatch('create')
    if (newDiffLog !== undefined) {
      diffLog.merge(newDiffLog)
    }

    if (getters.isCartSyncEnabled && getters.isCartConnected && !forceServerSilence) {
      const syncDiffLog = await dispatch('sync', { forceClientState: true, gifts, addedProducts: productsToAdd, action: 'add' })
      if (!config.cart.forceServerStateUponVsf && !syncDiffLog.isEmpty()) {
        diffLog.merge(syncDiffLog)
      }
    }
    dispatch('setActionProcessing', { isInProgress: false, actionToken: ACTION_TOKEN })

    return diffLog
  },
  // EXTENDING to add a hook that sets cart's state to processing
  async restoreQuantity ({ dispatch, commit }, { product }) {
    const ACTION_TOKEN = createActionProcessingToken('updateQuantity')
    dispatch('setActionProcessing', { isInProgress: true, actionToken: ACTION_TOKEN })
    const currentCartItem = await dispatch('getItem', { product })
    if (currentCartItem) {
      Logger.log('Restoring qty after error' + product.sku + currentCartItem.prev_qty, 'cart')()
      if (currentCartItem.prev_qty > 0) {
        await dispatch('updateItem', {
          product: {
            ...product,
            qty: currentCartItem.prev_qty
          }
        })
        EventBus.$emit('cart-after-itemchanged', { item: currentCartItem })
      } else {
        await dispatch('removeItem', { product: currentCartItem, removeByParentSku: false })
      }
    }
    dispatch('setActionProcessing', { isInProgress: false, actionToken: ACTION_TOKEN })
  },
  // EXTENDING to add a hook that sets cart's state to processing
  async updateQuantity ({ commit, dispatch, getters }, { product, qty, forceServerSilence = false }) {
    const ACTION_TOKEN = createActionProcessingToken('updateQuantity')
    dispatch('setActionProcessing', { isInProgress: true, actionToken: ACTION_TOKEN })
    commit(types.CART_UPD_ITEM, { product, qty })
    if (getters.isCartSyncEnabled && product.server_item_id && !forceServerSilence) {
      EventBus.$emit('add-to-cart', {
        product,
        coupon: getters['getCoupon']
      })
      const sync = await dispatch('sync', { forceClientState: true, addedProducts: [product], action: 'quantity' })
      dispatch('setActionProcessing', { isInProgress: false, actionToken: ACTION_TOKEN })
      return sync
    }
    const diffLog = await createDiffLog().pushClientParty({ status: 'wrong-qty', sku: product.sku, 'client-qty': qty })
    dispatch('setActionProcessing', { isInProgress: false, actionToken: ACTION_TOKEN })
    return diffLog
  },
  // EXTENDING to add a hook that sets cart's state to processing
  async removeItem ({ commit, dispatch, getters }, payload) {
    const ACTION_TOKEN = createActionProcessingToken('removeItem')
    dispatch('setActionProcessing', { isInProgress: true, actionToken: ACTION_TOKEN })
    const removeByParentSku = payload.product ? !!payload.removeByParentSku && payload.product.type_id !== 'bundle' : true
    const product = payload.product || payload
    const { cartItem } = cartHooksExecutors.beforeRemoveFromCart({ cartItem: product })

    commit(types.CART_DEL_ITEM, { product: cartItem, removeByParentSku })

    if (getters.isCartSyncEnabled && cartItem.server_item_id) {
      const diffLog = await dispatch('sync', { forceClientState: true, removedItem: product, action: 'remove' })
      cartHooksExecutors.afterRemoveFromCart(diffLog)
      dispatch('setActionProcessing', { isInProgress: false, actionToken: ACTION_TOKEN })

      return diffLog
    }

    const diffLog = createDiffLog().pushClientParty({ status: 'no-item', sku: product.sku })
    cartHooksExecutors.afterRemoveFromCart(diffLog)
    dispatch('setActionProcessing', { isInProgress: false, actionToken: ACTION_TOKEN })

    return diffLog
  },
  async syncTotals ({ commit, dispatch, getters, rootGetters },
    payload:
    { ignoreShipping: boolean, forceServerSync: boolean, methodsData?: any, skipPullingShippingMethods: boolean } =
    { ignoreShipping: false, forceServerSync: false, methodsData: null, skipPullingShippingMethods: false }) {
    const ACTION_TOKEN = createActionProcessingToken('syncTotals')
    dispatch('setActionProcessing', { isInProgress: true, actionToken: ACTION_TOKEN })
    if (payload.ignoreShipping) {
      await dispatch('overrideServerTotals', { hasShippingInformation: false, addressInformation: null })
      dispatch('setActionProcessing', { isInProgress: false, actionToken: ACTION_TOKEN })
      return
    }

    if (!payload.skipPullingShippingMethods) { await dispatch('pullMethods', { forceServerSync: payload.forceServerSync }) }

    if (getters.canSyncTotals && (getters.isTotalsSyncRequired || payload.forceServerSync)) {
      const shippingMethodsData = {
        ...createOrderData({
          shippingDetails: rootGetters['checkout/getShippingDetails'],
          shippingMethods: rootGetters['checkout/getShippingMethods'],
          paymentMethods: rootGetters['checkout/getPaymentMethods'],
          paymentDetails: rootGetters['checkout/getPaymentDetails']
        }),
        ...(payload.methodsData || {}) // just override shippingMethodsData with payload if provided
      }

      if (shippingMethodsData.country) {
        await dispatch('overrideServerTotals', {
          hasShippingInformation: shippingMethodsData.method_code || shippingMethodsData.carrier_code,
          addressInformation: createShippingInfoData(shippingMethodsData)
        })
        dispatch('setActionProcessing', { isInProgress: false, actionToken: ACTION_TOKEN })
        return
      }
      Logger.error('Please do set the tax.defaultCountry in order to calculate totals', 'cart')()
    }
    dispatch('setActionProcessing', { isInProgress: false, actionToken: ACTION_TOKEN })
  },
  async callCartAction ({ getters, rootGetters }, { action, itemToBeProcessed, correspondingItemFromCart }) {
    let cartItem = createCartItemForUpdate(itemToBeProcessed, null)
    if (correspondingItemFromCart && correspondingItemFromCart.server_item_id) {
      cartItem.item_id = correspondingItemFromCart.server_item_id
    }
    if (itemToBeProcessed.server_item_id) {
      cartItem.item_id = itemToBeProcessed.server_item_id
    }

    const shippingDetails = rootGetters['checkout/getShippingDetails']
    const checkoutStep = rootGetters['checkout/getStep']
    const storeView = currentStoreView()
    const address = checkoutStep >= 1 && rootGetters['url/getCurrentRoute'].name.includes('checkout')
      ? formatShippingAddress(shippingDetails, storeView) : {}
    const email = rootGetters['checkout/getPersonalDetails']?.emailAddress || null
    switch (action) {
      case 'add':
        if (correspondingItemFromCart?.server_item_id) {
          cartItem.qty = correspondingItemFromCart.qty
        }
        return CartService.updateItem(getters.getCartToken, cartItem as any, address as any, email)
      case 'quantity':
        return CartService.updateItem(getters.getCartToken, cartItem as any, address as any, email)
      case 'remove':
        return CartService.deleteItem(getters.getCartToken, cartItem as any, address as any, email)
      default:
        throw new Error(`Unknown action requested to be performed on the cart: ${action}`)
    }
  },
  async forceServerStateUponClient ({ commit, getters, dispatch }, { serverItems, clientItems, shippingMethods, totals }) {
    // Assumes modified magento endpoints
    const productsToErase =
      clientItems.filter(clientItem =>
        !serverItems.find(serverItem => productsEquals(clientItem, serverItem)))
    const productsToAdd =
      serverItems.filter(clientItem =>
        !clientItems.find(serverItem => productsEquals(clientItem, serverItem)))
    for (const productToAdd of productsToAdd) {
      const matchedProduct = await dispatch('getProductVariant', { serverItem: productToAdd })
      dispatch('addItemToStore', { product: matchedProduct })
    }
    serverItems.forEach(serverItem => {
      dispatch('updateItem', {
        product: {
          server_item_id: serverItem.item_id,
          server_cart_id: serverItem.quote_id,
          product_option: serverItem.product_option,
          type_id: serverItem.product_type,
          ...serverItem
        }
      })
    })
    const updates = [
      ...productsToErase.map(productToErase => {
        delete productToErase.server_item_id
        return dispatch('removeItem', { product: productToErase })
      })
    ]
    await Promise.all(updates)
    const totalsPayload = {
      response: {
        result: { totals },
        resultCode: 200
      }
    }
    dispatch('overrideServerTotals', totalsPayload)
    if (shippingMethods) {
      await dispatch('updateShippingMethods', { shippingMethods })
    }
    commit(types.CART_UPD_TOTALS, {
      itemsAfterTotal: serverItems,
      totals,
      platformTotalSegments: totals.total_segments
    })
    commit(types.CART_SET_TOTALS_SYNC)
  },
  async syncWithHighPerformance ({ getters, dispatch, commit }, { action, itemsToBeUpdated, clientItems, removedItems }) {
    let lastResponse
    const itemsToBeProcessed = removedItems || itemsToBeUpdated
    for await (const itemToBeProcessed of itemsToBeProcessed) {
      const correspondingItemFromCart = clientItems.find(clientItem => {
        return clientItem.checksum === itemToBeProcessed.checksum &&
          clientItem.sku === itemToBeProcessed.sku
      })

      lastResponse = await dispatch('callCartAction', { action, itemToBeProcessed, correspondingItemFromCart })

      /**
       * Perform cart re-sync in case there was an error in the response
       */
      if (lastResponse.code !== 200) {
        return dispatch('sync', { forceSync: true })
      }

      if (config.cart.forceServerStateUponVsf) {
        await dispatch('forceServerStateUponClient', {
          serverItems: lastResponse.result.items,
          clientItems,
          totals: lastResponse.result.totals,
          shippingMethods: lastResponse.result.shipping_methods
        })

        return {
          result: lastResponse.result.items,
          resultCode: lastResponse.code,
          totals: lastResponse.result.totals,
          shippingMethods: lastResponse.result.shipping_methods
        }
      }
      const serverItem = lastResponse.result.items.find(itemInResponse => itemInResponse.sku === itemToBeProcessed.sku)
      await dispatch('updateClientItem', { clientItem: itemToBeProcessed, serverItem })

      return {
        result: lastResponse.result.items,
        resultCode: lastResponse.code,
        totals: lastResponse.result.totals,
        shippingMethods: lastResponse.result.shipping_methods
      }
    }
  },
  async sync (
    { getters, rootGetters, commit, dispatch, state },
    { forceClientState = false,
      dryRun = false,
      mergeQty = false,
      forceSync = false,
      gifts = [],
      addedProducts = null,
      removedItem = null,
      action = null,
      initialCartPull = false
    }) {
    const syncingWithPerformance = addedProducts?.length || removedItem
    const shouldUpdateClientState = rootGetters['checkout/isUserInCheckout'] || forceClientState
    const { getCartItems, canUpdateMethods, isSyncRequired, bypassCounter } = getters
    if (!config.cart.forceServerStateUponVsf && (!canUpdateMethods || !isSyncRequired) && !forceSync) {
      return createDiffLog()
    }
    commit(types.CART_SET_SYNC)
    /*
      Default Magento endpoints have been modified to reduce the amount of requests required to operate on the most
      common cart operations, everything that's not optimized fallbacks to the default sync logic which ensures that
      in worst case scenario client will be synced with server using battle-tested core logic since it's
      a strategic user path.
    */
    const removedItems = removedItem ? [removedItem] : null

    const response = syncingWithPerformance
      ? await dispatch('syncWithHighPerformance', { action, itemsToBeUpdated: addedProducts, clientItems: getCartItems, removedItems }) : await CartService.getItems()
    const resultCode = response.resultCode
    let result = response.result
    if (config.cart.forceServerStateUponVsf && syncingWithPerformance) return
    const { serverItems, clientItems } = cartHooksExecutors.beforeSync({ clientItems: getCartItems, serverItems: result })
    if (resultCode === 200) {
      const diffLog = await dispatch('merge', {
        dryRun,
        clientItems: config.cart.forceServerStateUponVsf && syncingWithPerformance ? [] : clientItems,
        serverItems,
        forceClientState: shouldUpdateClientState && (addedProducts?.length || removedItem),
        mergeQty,
        initialCartPull,
        totals: response.totals,
        shippingMethods: response.shippingMethods
      })
      cartHooksExecutors.afterSync(diffLog)
      return diffLog
    }

    if (bypassCounter < config.queues.maxCartBypassAttempts) {
      Logger.log('Bypassing with guest cart' + bypassCounter, 'cart')()
      commit(types.CART_UPDATE_BYPASS_COUNTER, { counter: 1 })
      await dispatch('connect', { guestCart: true })
    }

    Logger.error(result, 'cart')
    cartHooksExecutors.afterSync(result)
    commit(types.CART_SET_ITEMS_HASH, getters.getCurrentCartHash)
    return createDiffLog()
  },
  async getProductVariant ({ dispatch }, { serverItem }) {
    try {
      const options = await dispatch('findProductOption', { serverItem })
      const singleProduct = await dispatch('product/single', { options }, { root: true })
      return {
        ...singleProduct,
        server_item_id: serverItem.item_id,
        qty: serverItem.qty,
        server_cart_id: serverItem.quote_id,
        product_option: serverItem.product_option || singleProduct.product_option,
        // Handling gifts
        isGift: serverItem?.extension_attributes?.mp_free_gifts?.is_free_gift || false,
        giftRuleId: +serverItem?.extension_attributes?.mp_free_gifts?.rule_id || null,
        giftPrice: serverItem?.extension_attributes?.mp_free_gifts_price
        // End of hack
      }
    } catch (e) {
      return null
    }
  },
  async updateTotalsAfterMerge ({ dispatch, getters, commit }, { clientItems, dryRun }) {
    if (dryRun) return

    if (getters.isTotalsSyncRequired && clientItems.length > 0) {
      // HACK isCheckout, prevents unnecessary sync calls that can be fired on checkout step
      const isOutsideCheckout = router.currentRoute.name !== 'checkout'
      await dispatch('syncTotals', { ignoreShipping: isOutsideCheckout })
      // END HACK
    }

    commit(types.CART_SET_ITEMS_HASH, getters.getCurrentCartHash)
  },
  setActionProcessing ({ commit }, { actionToken, isInProgress }) {
    if (isInProgress) return commit(types.ADD_ACTION_IN_PROGRESS, { actionToken })
    commit(types.REMOVE_ACTION_IN_PROGRESS, { actionToken })
  }
}
