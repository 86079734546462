import { Module } from 'vuex'
import actions from './actions'
import RootState from '@vue-storefront/core/types/RootState'
import { AdyenStore } from './types';

export const SET_ADYEN_PAYMENT_METHODS = 'SET_ADYEN_PAYMENT_METHODS'
export const SET_ORDER = 'SET_ORDER'

export const store: Module<AdyenStore, RootState> = {
  state: {
    adyenPaymentMethods: [],
    order: null
  },
  actions,
  mutations: {
    [SET_ADYEN_PAYMENT_METHODS] (state, methods) {
      state.adyenPaymentMethods = methods
    },
    [SET_ORDER] (state, order) {
      state.order = order
    }
  }
}
