import Vue from 'vue';
import Zendesk from './VueZendeskPlugin'
import { Logger } from '@vue-storefront/core/lib/logger';
import { isServer } from '@vue-storefront/core/helpers';
import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { afterRegistration } from './hooks/afterRegistration'

export const ZendeskModule: StorefrontModule = async function ({ appConfig }) {
  if (isServer) return;

  if (appConfig.zendesk?.disabled) return;

  if (appConfig.zendesk?.key) {
    let vertical = '60px'
    const cookies = JSON.parse(localStorage.getItem('shop/claims/cookiesAccepted'))

    if (cookies?.value) {
      vertical = '0px'
    }

    const zendeskConfig = {
      key: appConfig.zendesk.key,
      initTimeout: (appConfig.zendesk.initTimeout * 1000) || 0,
      disabled: appConfig.zendesk.disabled,
      hideOnLoad: appConfig.zendesk.hideOnLoad,
      settings: {
        webWidget: {
          zIndex: 98,
          offset: {
            vertical,
            mobile: {
              vertical
            }
          },
          color: {
            theme: 'var(--c-white)'
          }
        }
      }
    }
    Vue.use(Zendesk, zendeskConfig)
  } else {
    Logger.warn(
      'Zendesk extension is not working. Ensure Zendesk key is defined in config',
      'ZEN'
    )()
  }

  afterRegistration(appConfig)
}
