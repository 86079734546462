export enum OrderStatuses {
  COLLECTORBANK_NEW = 'collectorbank_new',
  COLLECTORBANK_ACKNOWLEDGED = 'collectorbank_acknowledged',
  PROCESSING = 'processing',
  HOLDED = 'holded',
  COLLECTORBANK_ONHOLD = 'collectorbank_onhold',
  COMPLETE = 'complete',
  CLOSED = 'closed',
  COLLECTORBANK_CANCELED = 'collectorbank_canceled'
}
