import * as types from '@vue-storefront/core/modules/cart/store/mutation-types'; // depends on cart module

import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { extendStore } from '@vue-storefront/core/helpers'
import { cartStore } from './store'

const isGiftInCart = (gifts, inCartGift): boolean => {
  return gifts.find(_ => _.rule_id === inCartGift.giftRuleId && _.id === inCartGift.id)
}

export const CartModuleExtension: StorefrontModule = function ({ store }) {
  store.subscribeAction({
    after: async (action) => {
      // HACK, after deleting product related to gift, VSF keeps gift in cart even if server's `pull` method responses correctly
      if (action.type !== 'cart/removeItem') return

      const giftsInCart = (store.getters['cart/getCartItems'] || []).filter(_ => _.isGift) // depends on cart module
      if (giftsInCart.length < 1) return

      const availableGifts = await store.dispatch('upsell-free-gifts/getGiftsInCart', null)
      for (const product of giftsInCart) {
        if (isGiftInCart(availableGifts, product)) continue
        store.commit(`cart/${types.CART_DEL_ITEM}`, { product, removeByParentSku: false }) // depends on cart module
      }
    }
  })
  extendStore('cart', cartStore)
}
