import { GetterTree } from 'vuex'
import CartState from '../types/CartState'
import RootState from '@vue-storefront/core/types/RootState'

const getters: GetterTree<CartState, RootState> = {
  getCartProcessingStatus: state => state.actionsInProgress.length > 0,
  getAppliedCouponCode: state => state.platformTotals ? state.platformTotals.coupon_code : false
}

export default getters
