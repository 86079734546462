import Vue from 'vue'
import { Store } from 'vuex'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { isServer } from '@vue-storefront/core/helpers'
import { dispatchEvents } from '../helpers'

export const isEnabled = (gtmId: string | null) => {
  return typeof gtmId === 'string' && gtmId.length > 0 && !isServer
}

export function afterRegistration (config, store: Store<any>) {
  if (isEnabled(config.googleTagManager.id)) {
    const storeView = currentStoreView()
    const currency = storeView.i18n.currencyCode

    dispatchEvents(Vue, store, currency)
  }
}
