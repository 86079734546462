import config from 'config';
import rootStore from '@vue-storefront/core/store'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers';
import { CartService } from '@vue-storefront/core/data-resolver'
import { convertAddressToMagentoFormat } from '../helpers/utils'
export const logtradeStore = {
  namespaced: true,
  state: {
    pickupPoints: [],
    selectedPickupPoint: null
  },
  getters: {
    getPickupPoints (state) {
      return state.pickupPoints || []
    },
    getSelectedPickupPoint (state) {
      return state.selectedPickupPoint
    }
  },
  mutations: {
    SET_PICKUP_POINTS (state, points) {
      state.pickupPoints = points;
    },
    SELECT_PICKUP_POINT (state, point) {
      state.selectedPickupPoint = point;
    }
  },
  actions: {
    async fetchPickupPoints (context) {
      const storeView = rootStore.getters['getCurrentStoreView']
      const storeCode = storeView.storeCode || config.defaultStoreCode

      const shippingMethod = context.rootState.cart.shipping?.method_code
      if (shippingMethod === config.logtrade.pickup_code) {
        const currentUserToken = rootStore.getters['user/getUserToken']
        const currentCartToken = rootStore.getters['cart/getCartToken']
        const pickupPointsEndpoint = processLocalizedURLAddress(getApiEndpointUrl(config.logtrade, 'pickup_points_endpoint'))
          .replace('{{token}}', (currentUserToken == null) ? '' : currentUserToken)
          .replace('{{cartId}}', (currentCartToken == null) ? '' : currentCartToken)
        const pickupPointsResponse = await fetch(pickupPointsEndpoint, {
          headers: {
            'Content-Type': 'application/json',
            'x-vs-store-code': storeCode
          }
        });
        const pointsResult = (await pickupPointsResponse.json()).result
        context.commit('SET_PICKUP_POINTS', Array.isArray(pointsResult) ? pointsResult : [])
      }
    },
    sendShippingInformation (context, { addressInformation }) {
      const pickupPoint = context.getters.getPickupPoints.find(pickupPoint =>
        pickupPoint.agentId === context.getters.getSelectedPickupPoint
      )
      const email = context.rootGetters['checkout/getPersonalDetails']?.emailAddress
      if (addressInformation.shippingCarrierCode && addressInformation.shippingMethodCode) {
        const shippingMethod = context.rootGetters['shipping/shippingMethods']
          .find(method => method.method_code === addressInformation.shippingMethodCode)

        if (!shippingMethod) return

        let logtradeOption = {}

        if (pickupPoint) {
          logtradeOption = {
            option_id: pickupPoint?.optionId || shippingMethod.extension_attributes.option_id,
            agent_id: pickupPoint?.agentId
          }
        } else if (shippingMethod?.extension_attributes?.option_id) {
          logtradeOption = {
            option_id: shippingMethod.extension_attributes.option_id,
            agent_id: pickupPoint?.agentId
          }
        }

        const addressInformationToSend = {
          billing_address: convertAddressToMagentoFormat(addressInformation.billingAddress),
          shipping_address: convertAddressToMagentoFormat(addressInformation.shippingAddress),
          shipping_carrier_code: addressInformation.shippingCarrierCode,
          shipping_method_code: addressInformation.shippingMethodCode,
          extension_attributes: { logtrade: logtradeOption }
        }
        addressInformationToSend.billing_address.email = email
        addressInformationToSend.shipping_address.email = email
        return CartService.setShippingInfo(addressInformationToSend)
      }
    },
    selectPickupPoint (context, pickupPoint) {
      context.commit('SELECT_PICKUP_POINT', pickupPoint)
    },
    async storeMethodData (_, logtradeMethodData) {
      const storeView = rootStore.getters['getCurrentStoreView']
      const storeCode = storeView.storeCode || config.defaultStoreCode

      const currentUserToken = rootStore.getters['user/getUserToken']
      const currentCartToken = rootStore.getters['cart/getCartToken']
      const storeMethodDataEndpoint = processLocalizedURLAddress(getApiEndpointUrl(config.logtrade, 'store_method_data_endpoint'))
        .replace('{{token}}', (currentUserToken == null) ? '' : currentUserToken)
        .replace('{{cartId}}', (currentCartToken == null) ? '' : currentCartToken)
      const methodDataResponse = await fetch(storeMethodDataEndpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-vs-store-code': storeCode },
        body: JSON.stringify(logtradeMethodData)
      });
      const { result } = await methodDataResponse.json()
      return result
    }
  }
}
