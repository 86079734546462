import { KlarnaPlugin, KlarnaEvents } from '../types'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

function getStorageTarget () {
  const storeView = currentStoreView()
  return storeView.storeCode + '/kco/shipping_method'
}

function validateShippingItem (selectedOption: object) {
  // eslint-disable-next-line no-unreachable
  const requiredProperties = [
    'name',
    'id',
    'price',
    'tax_amount',
    'tax_rate'
  ]

  try {
    requiredProperties.forEach((elm, index) => {
      const propertyExists = elm in selectedOption

      if (!propertyExists) {
        throw new Error(`Mandatory "${elm}" is missing for the shipping item, deleting local storage entry`)
      }
    })
  } catch (e) {
    console.error(e, selectedOption)
    localStorage.removeItem(getStorageTarget())
  }
}

const plugin: KlarnaPlugin = {
  name: 'savedShippingMethod',
  beforeCreate: ({ order, getters, rootState }) => {
    /**
     * Attempt to fix the cart totals inconsistency on the page reload & cart changes
     */
    localStorage.removeItem(getStorageTarget())
    return order;

    // eslint-disable-next-line no-unreachable
    const selectedShippingMethod = localStorage.getItem(getStorageTarget())
    if (selectedShippingMethod) {
      const selectedOption = JSON.parse(selectedShippingMethod)

      if (selectedOption) {
        validateShippingItem(selectedOption)
      }

      order.order_lines = order.order_lines.filter(line => line.type !== 'shipping_fee')
      order.order_lines.push({
        type: 'shipping_fee',
        quantity: 1,
        name: selectedOption.name,
        reference: selectedOption.method_code || selectedOption.id,
        total_amount: selectedOption.price,
        unit_price: selectedOption.price,
        total_tax_amount: selectedOption.tax_amount,
        tax_rate: selectedOption.tax_rate
      })
      order.selected_shipping_option = selectedOption

      let orderAmount = 0
      let orderTaxAmount = 0
      order.order_lines.forEach((orderLine) => {
        orderAmount += orderLine.total_amount
        orderTaxAmount += orderLine.total_tax_amount
      })
      order.order_amount = Math.round(orderAmount)
      order.order_tax_amount = Math.round(orderTaxAmount)
    }
    return order
  },
  on: {
    [KlarnaEvents.SHIPPING_OPTION_CHANGE] (data) {
      return;

      /* Watch shipping option event from Klarna */
      if (data.id) {
        localStorage.setItem(getStorageTarget(), JSON.stringify(data))
      }
    }
  }
}

export default plugin
