export interface GtmItem {
  item_id: string, // Protein requested it to be sku
  item_name: string,
  coupon?: string, // i.e SUMMER_FUN
  currency?: string, // i.e. SEK
  discount?: number,
  index?: number,
  item_list_id?: string,
  item_list_name?: string,
  price?: number,
  quantity?: number
}

export enum GtmEventType {
  ViewItemList = 'view_item_list',
  SelectItem = 'select_item',
  ViewItem = 'view_item',
  RemoveFromCart = 'remove_from_cart',
  AddToCart = 'add_to_cart',
  BeginCheckout = 'begin_checkout',
  AddShippingInfo = 'add_shipping_info',
  AddPaymentInfo = 'add_payment_info',
  AddToWishlist = 'add_to_wishlist',
  Purchase = 'purchase',
  Search = 'search',
  ViewCart = 'view_cart',
  ApprovedCookies = 'approved_cookies',
  DeclinedCookies = 'declined_cookies'
}

export interface GtmItemList {
  id: string,
  name: string
}

export const gtmItemLists = {
  ProductSliders: { id: 'product_sliders', name: 'Product Slider' },
  SearchResults: { id: 'search_results', name: 'Search Results' },
  ProductPage: { id: 'product_page', name: 'Product Page' },
  Cart: { id: 'cart', name: 'Cart' },
  CategoryPageListing: { id: 'category_page_listing', name: 'Category Page Listing' },
  Checkout: { id: 'checkout', name: 'Checkout' }
} as const
