import { Module } from 'vuex'
import FaqState from '../types/FaqState'
import actions from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
export const FaqStore: Module<FaqState, any> = {
  namespaced: true,
  state: {
    getfaqData: []
  },
  mutations,
  actions,
  getters
}
