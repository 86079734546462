import { Module } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import RootState from '@vue-storefront/core/types/RootState'
import ReviewState from '../types/ReviewState'
import OrderState from '../types/OrderState'
import { OrderStatuses } from '../types/OrderStatuses'
import { OrderStates } from '../types/OrderStates'

export const reviewStore: Module<ReviewState, RootState> = {
  namespaced: true,
  state: {
    reviews: {},
    totalPages: {},
    pagesDiscovered: {},
    totalReviews: {},
    summary: {},
    isLoading: false,
    errorMessage: ''
  },
  actions,
  mutations
}

export const orderStore: Module<OrderState, RootState> = {
  namespaced: true,
  state: {
    orders: [
      {
        order_id: '1',
        created_at: Date.now().toString(),
        updated_at: Date.now().toString(),
        transmited: false,
        transmited_at: '',
        status: OrderStatuses.COLLECTORBANK_NEW,
        state: OrderStates.New,
        user_id: '1',
        cart_id: '1',
        store_code: '1',
        store_id: 1,
        products: [
          {
            sku: 'SKU',
            qty: 1,
            name: 'Product name',
            price: 12,
            product_type: '5',
            total_price: 13
          }
        ],
        addressInformation: {
          shipping_address: {
            region: {
              region: 'Waw'
            },
            country_id: 'PL',
            street: ['3 Maja', '27/3'],
            telephone: '777777777',
            postcode: '01-000',
            city: 'Wisła',
            firstname: 'Kamil',
            lastname: 'Stoch'
          },
          billing_address: {
            region: {
              region: 'Mal'
            },
            country_id: 'PL',
            street: ['Fiołkowa', '4'],
            postcode: '00-000',
            city: 'Wrocław',
            firstname: 'Adam',
            lastname: 'Malysz'
          },
          shipping_method_code: 'string',
          shipping_carrier_code: 'string'
        },
        paymentInformation: {
          payment_method_code: 'string',
          payment_method_additional: 'any',
          shipping_price: 5,
          total_price: 40,
          subtotal_price: 36,
          tax_amount: 3,
          discount_amount: 2
        }
      }
    ]
  }
};
