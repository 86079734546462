import i18n from '@vue-storefront/i18n'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { KlarnaPlugin } from '../types'

const plugin: KlarnaPlugin = {
  name: 'address',
  beforeCreate: ({ order, rootState }) => {
    const shippingDetails = rootState.checkout.shippingDetails;
    const personalDetails = rootState.checkout.personalDetails;
    if (shippingDetails && shippingDetails.zipCode) {
      order.billing_address = {
        attention: '',
        care_of: '',
        city: shippingDetails.city,
        country: shippingDetails.country,
        family_name: shippingDetails.lastName,
        given_name: shippingDetails.firstName,
        house_extension: '',
        organization_name: '',
        phone: shippingDetails.phoneNumber,
        reference: '',
        region: '',
        street_address: shippingDetails.streetAddress,
        street_address2: '',
        street_name: '',
        street_number: '',
        title: '',
        email: personalDetails.emailAddress,
        postal_code: shippingDetails.zipCode
      };
      order.shipping_address = order.billing_address;
    }
    return order
  }
}

export default plugin
