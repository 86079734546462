export function convertAddressToMagentoFormat (address) {
  return {
    region: address.region,
    region_id: address.regionId,
    region_code: address.regionCode,
    country_id: address.countryId,
    street: address.street,
    postcode: address.postcode,
    city: address.city,
    firstname: address.firstname,
    lastname: address.lastname,
    email: address.email,
    telephone: address.telephone
  }
}
