import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import FeedbackState from '../types/FeedbackState'

const getters: GetterTree<FeedbackState, RootState> = {
  getCurrentStep: (state) => state.currentStep,
  getFormData: (state) => state.formData,
  getStepPercentage: (state) => state.stepPercentage,
  questionList: (state) => state.questions
}

export default getters
