import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import CartState from '../types/CartState'

const mutations: MutationTree<CartState> = {
  [types.ADD_ACTION_IN_PROGRESS] (state, { actionToken }) {
    const actionsListWithoutDuplicates = new Set([...state.actionsInProgress, actionToken])
    state.actionsInProgress = Array.from(actionsListWithoutDuplicates)
  },
  [types.REMOVE_ACTION_IN_PROGRESS] (state, { actionToken }) {
    state.actionsInProgress = state.actionsInProgress.filter(action => actionToken !== action)
  }
}

export default mutations
