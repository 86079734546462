import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import FeedbackState from '../types/FeedbackState'
import * as types from './mutation-types'
import fetch from 'isomorphic-fetch'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search';
import { SearchQuery } from 'storefront-query-builder'
import { Logger } from '@vue-storefront/core/lib/logger';
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl'
import config from 'config'

const actions: ActionTree<FeedbackState, RootState> = {
  list (context, { skipCache = false }) {
    let searchQuery = new SearchQuery();
    searchQuery = searchQuery.applyFilter({ key: 'is_enabled', value: { 'eq': '1' } })
    if (skipCache) {
      return quickSearchByQuery({
        query: searchQuery,
        entityType: 'feedback_questions'
      })
        .then(resp => {
          context.commit(types.SET_QUESTIONS, resp?.items || []);
          context.commit(types.SET_PROGRESSBAR_PERCENTAGE, (100 / resp?.items.length))
          return resp.items;
        })
        .catch(err => {
          Logger.error(err, 'Feedback Questions')();
        });
    } else {
      return new Promise((resolve, reject) => {
        let resp = context.state.questions;
        resolve(resp);
      });
    }
  },
  updateStep ({ commit }, step) {
    commit(types.SET_STEP, step)
  },
  saveFormData ({ commit, state }, formData) {
    commit('updateFormData', formData);
  },
  async submit ({ commit, state }, data) {
    const url = processLocalizedURLAddress(getApiEndpointUrl(config.feedback, 'submit'))
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      return result;
    } catch (error) {
      console.error('Error making POST request:', error);
    }
  }

}
export default actions
