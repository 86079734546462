import { MutationTree } from 'vuex'
import * as types from './mutation-types'
// import ReviewState from '../types/ReviewState'
import Vue from 'vue'

const mutations: MutationTree<any> = {
  [types.UPSELL_UPD_GIFTS] (state, { sku, gifts }) {
    Vue.set(state.gifts, sku, gifts)
  },
  [types.UPSELL_UPD_GIFTS_IN_CART] (state, { gifts }) {
    state.cart = gifts
  }
}

export default mutations
