import config from 'config';
import rootStore from '@vue-storefront/core/store'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers';

export const collectorStore = {
  namespaced: true,
  state: {
    src: null,
    token: null,
    lang: null,
    version: null,
    customer_type: null
  },
  getters: {
    getIframeInfo (state) {
      const { src, token, lang, version, customer_type } = state
      return { src, token, lang, version, customer_type }
    }
  },
  mutations: {
    SET_COLLECTOR_IFRAME (state, { src, token, lang, version, customer_type }) {
      state.src = src
      state.token = token
      state.lang = lang
      state.version = version
      state.customer_type = customer_type
    }
  },
  actions: {
    setCollectorIframe (context, { containerId, src, token, lang, version, customer_type }) {
      context.commit('SET_COLLECTOR_IFRAME', { containerId, src, token, lang, version, customer_type })
    },
    async setCustomerType (context, customerType) {
      const storeView = rootStore.getters['getCurrentStoreView']
      const storeCode = storeView.storeCode || config.defaultStoreCode
      const currentUserToken = rootStore.getters['user/getUserToken']
      const currentCartToken = rootStore.getters['cart/getCartToken']
      const collectorPaymentIframeEndpoint = processLocalizedURLAddress(getApiEndpointUrl(config.checkout, 'collector_payment_iframe_endpoint'))
        .replace('{{token}}', (currentUserToken == null) ? '' : currentUserToken)
        .replace('{{cartId}}', (currentCartToken == null) ? '' : currentCartToken)
        .replace('{{customerType}}', (customerType == null) ? 1 : customerType)

      const response = await fetch(collectorPaymentIframeEndpoint, {
        headers: {
          'Content-Type': 'application/json',
          'x-vs-store-code': storeCode
        }
      });

      const { result } = await response.json()
      const { containerId, src, token, lang, version, customer_type } = result;

      context.commit('SET_COLLECTOR_IFRAME', { containerId, src, token, lang, version, customer_type })
    }
  }
}
