import { GtmItem, GtmEventType, GtmItemList, gtmItemLists } from '../types/gtmEvents'
import config from 'config'
import isNumber from 'lodash-es/isNumber'
import omitBy from 'lodash-es/omitBy'
import isNil from 'lodash-es/isNil'

function createItem (
  dataTransferObject: { product, itemList?: GtmItemList, productIndexInList?: number, variant, discount?: number, coupon?: string }
): GtmItem {
  const currency = config.i18n.currencyCode || null
  const { product, itemList, productIndexInList, variant, discount, coupon } = dataTransferObject
  const { variantPrice } = getVariantInfo(product, variant)
  const price = variantPrice || getPrice(product) || null
  const appliedDiscount = product?.discount || discount || 0
  return omitBy({
    'item_id': product.parentSku || product.sku || null,
    'item_name': product.name || null,
    'coupon': coupon || null,
    'currency': currency,
    'discount': appliedDiscount,
    'index': isNumber(productIndexInList) ? productIndexInList : null,
    'item_list_id': itemList?.id || null,
    'item_list_name': itemList?.name || null,
    'price': price,
    'quantity': isNumber(product.qty) ? product.qty : null,
    'gtm_custom_dimensions': product.gtmCustomDimensions
  }, isNil)
}

function createItems (products: any, itemList?: GtmItemList, variant: any = null, discount = null, coupon = null) {
  return products.map((product, index) => {
    return createItem({ product, itemList, variant, productIndexInList: index, discount, coupon })
  })
}

function getPrice (product) {
  if (product.type_id === 'bundle') return product.price_range.min_price
  const tier = product.tier_prices?.reverse().find(item => {
    return item.qty <= product.qty
  })

  return tier ? tier.value : (product.special_price || product.final_price || product.regular_price || product.price)
}

function getVariantInfo (product, variant) {
  let variantName, variantPrice

  if (product.type_id === 'configurable' && variant) {
    const configuredChild = product.configurable_children.find(item => {
      return item[variant.type].toString() === variant.id
    })
    variantName = configuredChild.admin_name || configuredChild.name
    variantPrice = getPrice(configuredChild)
  }

  return {
    variantName,
    variantPrice
  }
}

export const dispatchEvents = function (vue, store, currency) {
  function pushToDataLayer (eventData) {
    vue.prototype.$gtm.dataLayer().push({ ecommerce: null });
    vue.prototype.$gtm.dataLayer().push(eventData);
  }

  function gtag (...args) {
    vue.prototype.$gtm.dataLayer().push(arguments)
  }

  const viewItemEvent = (product, itemList?: GtmItemList, variant?) => {
    const price = getPrice(product)
    const viewItem = {
      'event': GtmEventType.ViewItem,
      'ecommerce': {
        'currency': config.i18n.currencyCode,
        'value': price,
        'items': createItems([product], itemList, variant)
      }
    }
    pushToDataLayer(viewItem)
  }

  const viewItemListEvent = (products, itemList: GtmItemList) => {
    const viewItemList = {
      'event': GtmEventType.ViewItemList,
      'ecommerce': {
        'item_list_id': itemList.id,
        'item_list_name': itemList.name,
        'items': createItems(products, itemList)
      }
    }
    pushToDataLayer(viewItemList)
  }

  const selectItemEvent = (product, itemList: GtmItemList) => {
    const selectItemEvent = {
      'event': 'select_item',
      'ecommerce': {
        'item_list_id': itemList?.id,
        'item_list_name': itemList?.name,
        'items': createItems([product], itemList)
      }
    }
    pushToDataLayer(selectItemEvent)
  }

  const searchEvent = (searchTerm) => {
    const search = {
      'event': GtmEventType.Search,
      'searchTerm': searchTerm
    }
    pushToDataLayer(search)
  }

  const addToCartEvent = (product, coupon) => {
    const addToCart = omitBy({
      'event': GtmEventType.AddToCart,
      'ecommerce': {
        'currency': config.i18n.currencyCode,
        'value': getPrice(product) * product.qty,
        'items': createItems([product], null, Math.abs(coupon?.discount), coupon?.code)
      }
    }, isNil)
    pushToDataLayer(addToCart)
  }

  const removeFromCartEvent = (product, coupon) => {
    const addToCart = omitBy({
      'event': GtmEventType.RemoveFromCart,
      'ecommerce': {
        'currency': config.i18n.currencyCode,
        'value': getPrice(product) * product.qty,
        'items': createItems([product], null, null, Math.abs(coupon?.discount), coupon?.code)
      }
    }, isNil)
    pushToDataLayer(addToCart)
  }

  const beginCheckoutEvent = (products, coupon, totals) => {
    const beginCheckout = omitBy({
      'event': GtmEventType.BeginCheckout,
      'ecommerce': {
        'currency': config.i18n.currencyCode,
        'value': totals?.find(total => total.code === 'grand_total')?.value,
        'coupon': coupon?.code,
        'items': createItems(products, null, null, Math.abs(coupon?.discount), coupon?.code)
      }
    }, isNil)
    pushToDataLayer(beginCheckout)
  }

  const purchaseEvent = (is_new_customer, increment_id, grand_total, tax_amount, base_shipping_incl_tax, order_currency_code, items, currency_rates, shipping_cost, coupon_code, extension_attributes, payment_reference) => {
    let purchase
    try {
      purchase = omitBy({
        'event': GtmEventType.Purchase,
        'ecommerce': {
          'currency': config.i18n.currencyCode,
          'transaction_id': increment_id || payment_reference,
          'value': grand_total,
          'coupon': coupon_code,
          'shipping': base_shipping_incl_tax,
          'tax': tax_amount,
          'newCustomer': is_new_customer,
          'items': items,
          'currency_rates': currency_rates,
          'shipping_cost': shipping_cost
        }
      }, isNil)
    } catch (err) {
      console.error(err)
    }
    pushToDataLayer(purchase)
  }

  const errorEvent = (message) => {
    const error = {
      event: 'error',
      error_msg: message
    }
    pushToDataLayer(error)
  }

  const backInStockSubscribeEvent = (email, productSku, productId) => {
    const backInStockSubscribeData = {
      'event': 'subscribe_back_in_stock',
      'ecommerce': {
        'email': email,
        'productSku': productSku,
        'productId': productId
      }
    }
    pushToDataLayer(backInStockSubscribeData)
  }

  const cookieMessageBarGTM = (event) => {
    gtag('consent', 'update', {
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'ad_storage': 'granted',
      'analytics_storage': 'granted'
    })

    const cookieMsgEvent = {
      event: event
    }
    pushToDataLayer(cookieMsgEvent)
  }

  const invalidDiscountCodeEvent = (discountCode) => {
    const invalid_discount_code = {
      event: 'invalid_discount_code',
      discount_code: discountCode
    }
    pushToDataLayer(invalid_discount_code)
  }
  // Approved Cookies Event
  vue.prototype.$bus.$on('gtm-approved-cookies', () => {
    cookieMessageBarGTM(GtmEventType.ApprovedCookies);
  })
  // Declined Cookies Event
  vue.prototype.$bus.$on('gtm-declined-cookies', () => {
    cookieMessageBarGTM(GtmEventType.DeclinedCookies);
  })

  vue.prototype.$bus.$on('pdp-before-enter', (product, itemList?: GtmItemList) => {
    viewItemEvent(product, itemList)
  })

  vue.prototype.$bus.$on('product-variant-changed', (product, itemList?: GtmItemList, variant?) => {
    viewItemEvent(product, itemList, variant)
  })

  vue.prototype.$bus.$on('recommended-slider-updated', products => {
    viewItemListEvent(products, gtmItemLists.ProductSliders)
  })

  vue.prototype.$bus.$on('plp-on-enter', (products) => {
    viewItemListEvent(products, gtmItemLists.CategoryPageListing)
  })

  vue.prototype.$bus.$on('product-card-click', (product, itemList: GtmItemList) => {
    selectItemEvent(product, itemList)
  })

  vue.prototype.$bus.$on('after-product-search', (products, searchTerm) => {
    searchEvent(searchTerm)
    viewItemListEvent(products, gtmItemLists.SearchResults)
  })

  vue.prototype.$bus.$on('add-to-cart', ({ product, coupon }) => {
    addToCartEvent(product, coupon)
  })

  vue.prototype.$bus.$on('remove-from-cart', ({ product, coupon }) => {
    removeFromCartEvent(product, coupon)
  })

  vue.prototype.$bus.$on('checkout-on-email-input', ({ products, coupon, totals }) => {
    beginCheckoutEvent(products, coupon, totals)
  })

  vue.prototype.$bus.$on('checkout-confirmed', ({ is_new_customer, increment_id, grand_total, tax_amount, base_shipping_incl_tax, order_currency_code, items, currency_rates, shipping_cost, coupon_code, extension_attributes, payment_reference }) => {
    purchaseEvent(is_new_customer, increment_id, grand_total, tax_amount, base_shipping_incl_tax, order_currency_code, items, currency_rates, shipping_cost, coupon_code, extension_attributes, payment_reference)
  })

  vue.prototype.$bus.$on('error-notification', (message) => {
    errorEvent(message)
  })

  vue.prototype.$bus.$on('subscribe-back-in-stock', (email, productSku, productId) => {
    backInStockSubscribeEvent(email, productSku, productId)
  })

  vue.prototype.$bus.$on('invalid-discount-code', (discountCode) => {
    invalidDiscountCodeEvent(discountCode)
  })
}
