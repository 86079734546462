export const SN_CART = 'cart'
export const CART_ADD_ITEM = SN_CART + '/ADD'
export const CART_UPD_ITEM = SN_CART + '/UPD'
export const CART_DEL_ITEM = SN_CART + '/DEL'
export const UPD_MAGENTO_CART_STATE = SN_CART + '/UPD_MAGENTO_STATE'
export const CART_SET_SYNC = SN_CART + '/MARK_SYNC'
export const CART_UPDATE_BYPASS_COUNTER = SN_CART + '/UPD_BYPASS_COUNTER'
export const CART_SET_ITEMS_HASH = SN_CART + '/SAVE_HASH'
export const ADD_ACTION_IN_PROGRESS = SN_CART + '/ADD_ACTION_IN_PROGRESS'
export const REMOVE_ACTION_IN_PROGRESS = SN_CART + '/REMOVE_ACTION_IN_PROGRESS'
export const CART_LOAD_CART = SN_CART + '/LOAD'
export const CART_UPD_TOTALS = SN_CART + '/UPD_TOTALS'
export const CART_SET_TOTALS_SYNC = SN_CART + '/MARK_TOTALS_SYNC'
