import Vue from 'vue'
import { isServer } from '@vue-storefront/core/helpers'

export const isEnabled = (isDisabled: boolean | null, zendeskKey: string | null) => {
  return !isDisabled && typeof zendeskKey === 'string' && zendeskKey.length > 0 && !isServer
}

export function afterRegistration (config) {
  if (isEnabled(config.zendesk.disabled, config.zendesk.key)) {
    Vue.prototype.$bus.$on('cookies-accepted', () => {
      Vue.prototype.$zendesk.updateSettings({
        webWidget: {
          offset: {
            vertical: '0px',
            mobile: {
              vertical: '0px'
            }
          }
        }
      })
    })
  }
}
