import { Module } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import CartState from '../types/CartState'

export const cartStore: Module<CartState, any> = {
  namespaced: true,
  state: {
    platformTotals: null,
    actionsInProgress: []
  },
  getters,
  actions: actions as any,
  mutations
}
