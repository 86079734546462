export const trustBadgeStore = {
  namespaced: true,
  state: {
    tsid: ''
  },
  getters: {
    getTrustbadgeId (state) {
      return state.tsid
    }
  },
  mutations: {
    SET_TRUSTEDSHOPS_ID (state, { id }) {
      state.tsid = id
    }
  },
  actions: {
    init (context, tsid) {
      context.commit('SET_TRUSTEDSHOPS_ID', tsid)
      if (tsid !== '') {
        (function () {
          console.log('YS =>', 'Inside function')
          var _ts = document.createElement('script');
          _ts.setAttribute('data-desktop-y-offset', '24');
          _ts.setAttribute('data-mobile-y-offset', '20');
          _ts.setAttribute('data-desktop-disable-reviews', 'false');
          _ts.setAttribute('data-desktop-enable-custom', 'false');
          _ts.setAttribute('data-desktop-position', 'right');
          _ts.setAttribute('data-desktop-custom-opening-direction', 'topright');
          _ts.setAttribute('data-desktop-custom-width', '150');
          _ts.setAttribute('data-desktop-enable-fadeout', 'false');
          _ts.setAttribute('data-disable-mobile', 'false');
          _ts.setAttribute('data-disable-trustbadge', 'false');
          _ts.setAttribute('data-mobile-custom-width', '150');
          _ts.setAttribute('data-mobile-custom-opening-direction', 'topright');
          _ts.setAttribute('data-mobile-disable-reviews', 'false');
          _ts.setAttribute('data-mobile-enable-custom', 'false');
          _ts.setAttribute('data-mobile-position', 'left');
          _ts.setAttribute('data-mobile-enable-topbar', 'false');
          _ts.type = 'text/javascript';
          _ts.charset = 'utf-8';
          _ts.async = true;
          _ts.src = 'https://widgets.trustedshops.com/js/' + tsid + '.js';
          var __ts = document.getElementsByTagName('script')[0];
          __ts.parentNode.insertBefore(_ts, __ts);
        })();
      } else {
        console.log('Trusted Shops: Trustbadge is not configured for this shop\'s market/language.')
      }
    }
  }
}
