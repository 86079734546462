import config from 'config';
import { ActionTree } from 'vuex';
import FaqState from '../types/FaqState';
import * as types from './mutation-types'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers';
import { quickSearchByQuery } from '@vue-storefront/core/lib/search';
import { SearchQuery } from 'storefront-query-builder'
import { Logger } from '@vue-storefront/core/lib/logger';

// const entityKey = 'faqs'

const actions: ActionTree<FaqState, any> = {
  list (context, { filterField = null, filterValues = null, skipCache = false }) {
    let searchQuery = new SearchQuery();
    if (filterValues) {
      searchQuery = searchQuery.applyFilter({ key: filterField, value: { eq: filterValues } })
    }
    if (skipCache || !context.state.getfaqData || context.state.getfaqData.length === 0) {
      return quickSearchByQuery({
        query: searchQuery,
        entityType: 'faqs',
        sort: 'id:asc'
      })
        .then(resp => {
          context.commit(types.FAQ_DATA, resp?.items || []);
          return resp.items;
        })
        .catch(err => {
          Logger.error(err, 'Faq')();
        });
    } else {
      return new Promise((resolve, reject) => {
        let resp = context.state.getfaqData;
        resolve(resp);
      });
    }
  },
  async like (context, payload) {
    const faqId = payload.faqId
    const faqCountEndpoint = processLocalizedURLAddress(getApiEndpointUrl(config.faqs, 'faq_like')).replace('{{faqid}}', faqId)
    try {
      return await fetch(faqCountEndpoint, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }).then(res => res.json()).then(res => res);
    } catch (e) {
      console.log(e.message)
    }
  },
  async view (context, payload) {
    const faqId = payload.faqId
    const faqCountEndpoint = processLocalizedURLAddress(getApiEndpointUrl(config.faqs, 'faq_view')).replace('{{faqid}}', faqId)
    try {
      return await fetch(faqCountEndpoint, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }).then(res => res.json()).then((resp) => {
      });
    } catch (e) {
      console.log(e.message)
    }
  },
  async dislike (context, payload) {
    const faqId = payload.dislikeReason.faqId
    const faqCountEndpoint = processLocalizedURLAddress(getApiEndpointUrl(config.faqs, 'faq_dislike')).replace('{{faqid}}', faqId)
    try {
      return await fetch(faqCountEndpoint, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }).then(res => res.json()).then(res => res)
    } catch (e) {
      console.log(e.message)
    }
  }
}

export default actions
