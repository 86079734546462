import { Module } from 'vuex'
import RootState from 'core/types/RootState'
import actions from './actions'
import mutations from './mutations'

export const upsell: Module<any, RootState> = {
  namespaced: true,
  state: {
    gifts: {},
    cart: []
  },
  actions,
  mutations
}
