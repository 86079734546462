export const SN_REVIEW = 'review'
export const REVIEW_SET_REVIEWS = SN_REVIEW + '/SET_REVIEWS'
export const REVIEW_UPD_REVIEWS = SN_REVIEW + '/UPD_REVIEWS'
export const REVIEW_UPD_TOTAL_PAGES = SN_REVIEW + '/UPD_TOTAL_PAGES'
export const REVIEW_UPD_PAGES_DISCOVERED = SN_REVIEW + '/UPD_PAGES_DISCOVERED'
export const REVIEW_UPD_TOTAL_REVIEWS = SN_REVIEW + '/UPD_TOTAL_REVIEWS'
export const REVIEW_UPD_SUMMARY = SN_REVIEW + '/UPD_SUMMARY'
export const REVIEW_IS_LOADING = SN_REVIEW + '/IS_LOADING'
export const REVIEW_IS_ERROR = SN_REVIEW + '/IS_ERROR'
export const DUMP_CACHE = SN_REVIEW + '/DUMP_CACHE'
