import * as types from '@vue-storefront/core/modules/catalog/store/product/mutation-types'
import { getProductGallery } from '@vue-storefront/core/modules/catalog/helpers'

const mapMediaItems = (mediaItem) => ({
  ...mediaItem,
  image: mediaItem?.product_images?.gallery
})

const mapProductItem = (productItem) => ({
  ...mapMediaItems(productItem),
  media_gallery: productItem?.media_gallery?.map(mapMediaItems)
})

const actions = {
  /**
   * Set product gallery depending on product type
   */

  async setProductGallery (context, { product }) {
    if (product && product && product.configurable_children) {
      product?.configurable_children.reverse()
    }
    const productGallery = getProductGallery({
      ...mapProductItem(product),
      configurable_children: product.configurable_children.map(mapProductItem)
    })
    context.commit(types.PRODUCT_SET_GALLERY, productGallery)
  }
}

export default actions
