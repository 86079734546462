import { ActionTree } from 'vuex'
import config from 'config'
import RootState from '@vue-storefront/core/types/RootState';
import { processURLAddress } from '@vue-storefront/core/helpers';
import { AdyenStore } from './types';
import { SET_ADYEN_PAYMENT_METHODS, SET_ORDER } from './index';
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { notifications } from '@vue-storefront/core/modules/cart/helpers';
import { router } from '@vue-storefront/core/app';
import i18n from '@vue-storefront/i18n';

const actions: ActionTree<AdyenStore, RootState> = {
  async storeOrder ({ commit }, order) {
    const orderId = order.order_id
    const storage = StorageManager.get('adyen')
    storage.setItem('pendingOrderId', orderId)
    commit(SET_ORDER, order)
  },
  async getPaymentMethods ({ commit, rootGetters }) {
    const currentUserToken = rootGetters['user/getUserToken']
    const currentCartToken = rootGetters['cart/getCartToken']
    const url = processURLAddress(config.adyen.paymentMethodsUrl)
      .replace('{{token}}', currentUserToken)
      .replace('{{cartId}}', currentCartToken)

    const response = await fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })

    const { result } = await response.json()
    if (!result?.paymentMethodsResponse?.paymentMethods) {
      return null
    }

    commit(SET_ADYEN_PAYMENT_METHODS, result)

    return result
  },

  async placeOrder ({ rootGetters, rootState, dispatch, commit }, paymentMethod) {
    const currentUserToken = rootGetters['user/getUserToken']
    const currentCartToken = rootGetters['cart/getCartToken']
    const paymentDetails = rootGetters['checkout/getPaymentDetails']
    const email = rootState.checkout.personalDetails.emailAddress
    const url = processURLAddress(config.adyen.storePaymentInfoUrl)
      .replace('{{token}}', currentUserToken)

    // Refactor to function
    const payload = {
      email,
      cartId: currentCartToken,
      origin: window.origin,
      billingAddress: {
        firstname: paymentDetails.firstName,
        lastname: paymentDetails.lastName,
        city: paymentDetails.city,
        postcode: paymentDetails.zipCode,
        street: [paymentDetails.streetAddress],
        countryId: paymentDetails.country,
        telephone: paymentDetails.phoneNumber
      },
      paymentMethod: paymentMethod
    }

    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify(payload)
    })

    const { result, code } = await response.json()
    if (code !== 201) return { error: result }

    const { order } = result
    await dispatch('storeOrder', order)

    const statusResponse = await dispatch('checkPaymentStatus')

    return { ...statusResponse, order }
  },

  async checkPaymentStatus ({ state, rootGetters }, id = null) {
    id = id || state.order?.entity_id
    const currentUserToken = rootGetters['user/getUserToken']
    const url = processURLAddress(config.adyen.paymentStatusUrl)
      .replace('{{token}}', currentUserToken)
      .replace('{{orderId}}', `${id}`)

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      },
      mode: 'cors'
    })
    const { result } = await response.json()

    return {
      status: result,
      orderId: id
    }
  },

  async submitAdditionalDetails ({ rootGetters }, { stateData = null, orderId = null }) {
    orderId = orderId || await StorageManager.get('adyen').getItem('pendingOrderId')
    const currentUserToken = rootGetters['user/getUserToken']
    const url = processURLAddress(config.adyen.paymentDetailsUrl)
      .replace('{{token}}', currentUserToken)
      .replace('{{orderId}}', orderId)

    stateData.orderId = orderId
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      },
      mode: 'cors',
      body: JSON.stringify(stateData)
    })
    const { result } = await response.json()
    return result
  },

  async checkRedirectResult ({ dispatch }, urlQueryParams = {}) {
    const redirectResult = urlQueryParams.redirectResult
    const orderId = urlQueryParams.orderId || null

    const payload = {
      details: {
        redirectResult: decodeURIComponent(redirectResult)
      }
    }
    const response = await dispatch('submitAdditionalDetails', { stateData: payload, orderId: orderId })
    return response
  },

  async showFinalResult ({ dispatch, rootState }, response) {
    const successResults = [
      'Authorised',
      'Pending',
      'Received'
    ]
    const isPaymentAccepted = successResults.indexOf(response.resultCode) !== -1

    if (isPaymentAccepted) {
      dispatch('cart/clear', { sync: false }, { root: true })
      const notOnConfirmationPage = rootState.route?.name !== 'checkout-confirmation'
      if (notOnConfirmationPage) router.push({ name: 'checkout-confirmation' })
    } else {
      router.push({ name: 'checkout' })
      dispatch(
        'notification/spawnNotification',
        notifications.createNotification({ type: 'danger', message: i18n.t('Try again or choose a different payment method.') }), { root: true }
      );
    }
    return isPaymentAccepted
  }
}

export default actions
