import { Module } from 'vuex'
import FeedbackState from '../types/FeedbackState'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const feedbackModule: Module<FeedbackState, any> = {
  namespaced: true,
  state: {
    currentStep: 0,
    formData: {},
    questions: [],
    stepPercentage: 0
  },
  actions,
  getters,
  mutations
}
