import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import ReviewState from '../types/ReviewState'
import Vue from 'vue'
import config from 'config'
const mutations: MutationTree<ReviewState> = {
  [types.DUMP_CACHE] (state, { sku }) {
    Vue.set(state.reviews, sku, null)
  },
  [types.REVIEW_SET_REVIEWS] (state, { sku, reviews }) {
    Vue.set(state.reviews, sku, reviews)
  },
  [types.REVIEW_UPD_REVIEWS] (state, { sku, reviews, page, total, perPage }) {
    let reviewsSlots = state.reviews[sku] || [...new Array(total)].fill(null)
    if (reviews) {
      const newReviews = reviewsSlots.map((slot, slotIndex) => {
        const indexInResponse = slotIndex - ((page - 1) * perPage)
        if (reviews[indexInResponse]) return reviews[indexInResponse]
        return slot
      })
      Vue.set(state.reviews, sku, newReviews)
    }
  },
  [types.REVIEW_UPD_PAGES_DISCOVERED] (state, { page, sku }) {
    if (state.pagesDiscovered[sku] > page) return
    Vue.set(state.pagesDiscovered, sku, page)
  },
  [types.REVIEW_UPD_TOTAL_PAGES] (state, { totalPages, sku }) {
    Vue.set(state.totalPages, sku, totalPages)
  },
  [types.REVIEW_UPD_TOTAL_REVIEWS] (state, { totalReviews, sku }) {
    Vue.set(state.totalReviews, sku, totalReviews)
  },
  [types.REVIEW_UPD_SUMMARY] (state, { summary }) {
    summary.forEach(summaryEntry => {
      if (!summaryEntry.breakdown && config.stampedIo.displayDistribution) {
        summaryEntry.breakdown = {
          rating1: 0,
          rating2: 0,
          rating3: 0,
          rating4: 0,
          rating5: 0
        }
      }
      const summarySchema = {
        count: summaryEntry.count,
        rating: summaryEntry.rating,
        distribution: Object.values(summaryEntry.breakdown || {})
      }
      Vue.set(state.summary, summaryEntry.productId, summarySchema)
    })
  },
  [types.REVIEW_IS_LOADING] (state, { status }) {
    state.isLoading = status
  },
  [types.REVIEW_IS_ERROR] (state, { message }) {
    state.errorMessage = message
  }
}

export default mutations
