import config from 'config'
import fetch from 'isomorphic-fetch'
import { ActionTree } from 'vuex'
import rootStore from '@vue-storefront/core/store'
import RootState from '@vue-storefront/core/types/RootState'
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import * as types from './mutation-types'

const actions: ActionTree<any, RootState> = {
  async getFreeGifts (context, { mainProductSku, productsSkus }: { mainProductSku: number, productsSkus: number[] }): Promise<any[]> {
    if (!config.mpfreegifts.mpfreegifts) {
      return [];
    }
    const skusQueryString = productsSkus.map(sku => `skus[]=${sku}`).join('&')
    const giftsEndpoint = processLocalizedURLAddress(getApiEndpointUrl(config.mpfreegifts, 'gift_item_endpoint'))
      .replace('{{skus}}', skusQueryString)
    const request = await fetch(giftsEndpoint);
    const response = await request.json();
    context.commit(types.UPSELL_UPD_GIFTS, { sku: mainProductSku, gifts: response.result })
  },
  async getGiftsInCart (): Promise<any[]> {
    if (!config.mpfreegifts.mpfreegifts) {
      return [];
    }
    const currentUserToken = rootStore.getters['cart/getCartToken'] // TODO: reactor getter to cart/getCartToken
    const currentCartToken = rootStore.getters['cart/getCartToken']
    const giftsEndpoint = processLocalizedURLAddress(getApiEndpointUrl(config.mpfreegifts, 'gifts_in_cart_endpoint'))
      .replace('{{token}}', currentUserToken)
      .replace('{{cartId}}', currentCartToken)
    const request = await fetch(giftsEndpoint);
    const response = await request.json();
    return response.result
  },
  async applyFreeGift (context, { ruleId, giftId }): Promise<Response> {
    const cartStorage = StorageManager.get('cart')
    const cartToken = await cartStorage.getItem('current-cart-token')
    const url = processLocalizedURLAddress(getApiEndpointUrl(config.mpfreegifts, 'add_to_cart_endpoint'))
    const body = { quoteId: cartToken, ruleId: +ruleId, giftId: +giftId }
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(body),
      headers
    }
    const request = new Request(url, requestOptions)
    return fetch(request)
  }
}

export default actions
