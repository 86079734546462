import { ActionTree } from 'vuex'
import flatMap from 'lodash.flatmap';

import config from 'config'
import RootState from '@vue-storefront/core/types/RootState'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

// @ts-ignore
import { getProductPrice } from '~/theme/helpers';
import RecommendationsState from '../types/RecommendationsState'
import * as types from './mutation-types'
import { Logger } from '@vue-storefront/core/lib/logger'

const SCRIPT_ID = config.klaviyo.scriptTagId as string
const PRODUCT_URL = (product) => {
  let url: any = formatProductLink(product, currentStoreView().storeCode);
  url = url.startsWith('/') ? url.substring(1) : url;
  return `${window.location.hostname}/${url}`
}
const actions: ActionTree<RecommendationsState, RootState> = {
  init (context): void {
    window._learnq = window._learnq || []
    const source = config.klaviyo.scriptUrl
    const publicApiKey = config.klaviyo.publicApiKey
    const klaviyoApiUrl = `${source}?company_id=${publicApiKey}`
    const TAG_TO_CREATE = 'script'
    context.dispatch('identifyUser')
    if (document.getElementById(SCRIPT_ID)) return
    context.dispatch('loadFromCache')
    let klaviyoScriptTag = document.createElement(TAG_TO_CREATE)
    klaviyoScriptTag.type = 'text/javascript'
    klaviyoScriptTag.async = true
    klaviyoScriptTag.src = klaviyoApiUrl
    klaviyoScriptTag.id = SCRIPT_ID
    let firstScriptTag = document.getElementsByTagName(TAG_TO_CREATE)[0]
    firstScriptTag.parentNode.insertBefore(klaviyoScriptTag, firstScriptTag)
  },
  async loadFromCache (context) {
    const klaviyoStorage = StorageManager.get('klaviyo')
    const email = await klaviyoStorage.getItem('userIdentifierEmail')
    if (email) context.dispatch('setCustomerEmail', { email })
  },
  identifyUser (context) {
    const email = context.state.userIdentifierEmail
    if (!email) return
    window._learnq.push(['identify', {
      $email: email
    }]);
  },
  setCustomerEmail (context, { email }: { email: string }) {
    const klaviyoStorage = StorageManager.get('klaviyo')
    klaviyoStorage.setItem('userIdentifierEmail', email)
    context.commit(types.UPD_CUTOMER_EMAIL, { email })
  },
  reportProductPageVisit (context, { product, price, link }: {
    product: any,
    price: { special: number, regular: number, string },
    link: string
  }) {
    window._learnq.push(['track', 'Viewed Product', {
      'ProductName': product.name,
      'ProductID': product.id,
      'SKU': product.sku,
      'Categories': product.category.map(category => category.name),
      'ImageURL': product.product_images.thumbnail,
      'URL': PRODUCT_URL(product),
      'Brand': 'Protein',
      'Price': price.special,
      'CompareAtPrice': price.regular
    }]);
  },
  reportAddToCart (context, { cartProducts, totalPrice, addedProduct, checkoutUrl }: {
    cartProducts: any[],
    totalPrice: number,
    addedProduct: any,
    checkoutUrl: string
  }) {
    const cartItems = cartProducts.map(product => {
      const price = getProductPrice(product, {}, true)
      return {
        'ProductID': product.id,
        'SKU': product.sku,
        'ProductName': product.name,
        'Quantity': product.qty,
        'ItemPrice': price.regular,
        'RowTotal': price.special || price.regular,
        'ProductURL': PRODUCT_URL(product),
        'ImageURL': product?.product_images?.thumbnail,
        'ProductCategories': product?.category?.map(category => category.name)
      }
    })

    const addedProductPrice = getProductPrice(addedProduct, {}, true)
    try {
      if (window._learnq) {
        window._learnq.push(['track', 'Added to Cart', {
          '$value': totalPrice,
          'AddedItemProductName': addedProduct.name,
          'AddedItemProductID': addedProduct.id,
          'AddedItemSKU': addedProduct.sku,
          'AddedItemCategories': addedProduct.category.map(category => category.name),
          'AddedItemImageURL': addedProduct.product_images.thumbnail,
          'AddedItemURL': PRODUCT_URL(addedProduct),
          'AddedItemPrice': addedProductPrice.special || addedProductPrice.regular,
          'AddedItemQuantity': addedProduct.qty,
          'ItemNames': cartItems.map(item => item.ProductName),
          'CheckoutURL': `${window.location.hostname}/${checkoutUrl}`,
          'Items': cartItems
        }]);
      }
    } catch (error) {
      Logger.error('An error occurred while pushing the event data:', 'cache', error)()
    }
  },
  reportStartedCheckout (context, { totalPrice, products, checkoutUrl, cartToken }: {
    eventId: string,
    totalPrice: number,
    products: any[],
    checkoutUrl: string,
    cartToken: string
  }) {
    const categoriesNames = flatMap(products, product => product.category.map(category => category.name))
    const items = products.map(product => {
      const price = getProductPrice(product, {}, true)
      return {
        'ProductID': product.id,
        'SKU': product.sku,
        'ProductName': product.name,
        'Quantity': product.qty,
        'ItemPrice': price.regular,
        'RowTotal': price.special || price.regular,
        'ProductURL': PRODUCT_URL(product),
        'ImageURL': product.product_images.thumbnail,
        'ProductCategories': product.category.map(category => category.name)
      }
    })
    const timestamp = Math.round((new Date().getTime()) / 1000)
    const checkoutEvent = {
      '$event_id': `${cartToken}_${timestamp}`,
      '$value': totalPrice,
      'ItemNames': products.map(product => product.name),
      'CheckoutURL': `${window.location.hostname}/${checkoutUrl}`,
      'Categories': categoriesNames,
      'Items': items
    }
    window._learnq.push(['track', 'Started Checkout', checkoutEvent]);
  },
  subscribeToBackInStock (context, payload) {
    const url = `https://a.klaviyo.com/client/back-in-stock-subscriptions/?company_id=${config.klaviyo.publicApiKey}`;
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        revision: config.klaviyo.revision,
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        data: {
          type: 'back-in-stock-subscription',
          attributes: {
            channels: ['EMAIL'],
            profile: { data: { type: 'profile', attributes: { email: payload.email } } }
          },
          relationships: { variant: { data: { type: 'catalog-variant', id: `$custom:::$default:::${payload.product.sku}` } } }
        }
      })
    };
    fetch(url, options)
  }
}
export default actions
