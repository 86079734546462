import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import RecommendationsState from '../types/RecommendationsState'
import Vue from 'vue'

const mutations: MutationTree<RecommendationsState> = {
  [types.UPD_CUTOMER_EMAIL] (state, { email }: { email: string }) {
    state.userIdentifierEmail = email
  }
}

export default mutations
